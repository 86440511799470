import * as Configs from "../config";
import { makeAutoObservable } from "mobx";

const chain = sessionStorage.getItem("chain");
export class ChainStore {
  chain = chain ? JSON.parse(chain) : Configs.CHAINS[0];

  constructor() {
    makeAutoObservable(this);
  }

  updateChain(data: any) {
    if (!data) return;
    try {
      sessionStorage.setItem("chain", JSON.stringify(data));
      this.chain = data;
      window.location.reload();
    } catch (e) {
      console.log("Error in updating chain...", e);
    }
  }
}

export const STORE_CHAIN = "chainStore";
