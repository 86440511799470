import { useState } from "react";
import { history } from "src/stores";
import { SelectChain } from "./select-chain";
import { MobileMenu } from "./mobile-menu";

export const Header = (props: any) => {
  const MENU = [
    {
      name: "Beta",
      url: "/beta",
    },
    {
      name: "Games",
      url: "/games",
    },
    {
      name: "Rules",
      url: "/rules",
    },
    {
      name: "Account",
      url: "/account",
    },
  ];

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div className="fixed bg-transparent w-full px-[30px] py-[10px] flex flex-row align-center items-center z-[100]">
      <div className="flex flex-row align-center items-center flex-1 ">
        <img
          src="/assets/logo_without_bg.png"
          alt="logo"
          className="h-[80px] cursor-pointer"
          onClick={() => history.push("/")}
        />
      </div>

      {/* Menu */}
      <div className="flex flex-row justify-center items-center">
        {MENU.map((item, i) => {
          const isCurrentPage =
            window.location.pathname.indexOf(item.name.toLowerCase()) > -1;
          return (
            <div key={i} className="pl-[50px] desktop">
              <p
                className={`cursor-pointer hover:text-primary transition-all duration-500 ${
                  isCurrentPage ? "txt-gradient" : ""
                }`}
                onClick={() => item.url && history.push(item.url)}
              >
                {item.name}
              </p>
            </div>
          );
        })}
        {/* Select Chain Icon */}
        <div className="pl-[0px] md:pl-[30px]">
          <SelectChain />
        </div>
        {/* Mobile Menu Icon */}
        <div className="pl-[30px]">
          <img
            src="/assets/menu.png"
            alt="menu"
            className="h-[20px] cursor-pointer"
            onClick={() => setIsMenuOpen(true)}
          />
        </div>
      </div>

      {/* Mobile Menu */}
      <MobileMenu
        isMenuOpen={isMenuOpen}
        setIsMenuOpen={setIsMenuOpen}
        menu={MENU}
      />
    </div>
  );
};
