import { openWarningNotification } from "src/components";

export const errorHandling = (e: any) => {
  const msg = e?.response?.data?.msg || null;
  switch (msg) {
    case "jwt_unauthorized":
      sessionStorage.removeItem("fortuna-lab-token");
      return;
    case "not_enough_flp":
      openWarningNotification("Not enough FLP to stake!");
      break;
    case "password_incorrect":
      openWarningNotification("Your password is incorrect");
      break;
    case "password_not_set":
      openWarningNotification("Your password is not set yet");
      break;
    case "game_does_not_accept_bet":
      openWarningNotification("Game does not accept staking!");
      break;
    case "side_reached_maximum":
      openWarningNotification("Side has reached maximum players!");
      break;
    case "already_in_game":
      openWarningNotification("You already in either side of the game!");
      break;
    case "side_not_accept":
    case "unable_to_get_game":
      openWarningNotification("Please try again later");
      break;
    default:
      break;
  }
  return msg;
};

export const isResultSuccess = (result: any) => {
  return result && result["data"] && result["data"]["success"];
};

export const refreshNewToken = (result: any) => {
  const token = result["data"]["refreshToken"] || null;
  if (!token) return;
  return sessionStorage.setItem("fortuna-lab-token", token);
};

export const setHeader = () => {
  let config = {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("fortuna-lab-token")}`,
    },
  };

  return config;
};
