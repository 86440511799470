import { useEffect, useState } from "react";
import { useLeaderboardStore } from "src/stores";
import { LoadingIcon } from "./loading-icon";

export const Leaderboard = (props: any) => {
  const [ranking, setRanking] = useState<any>([]);

  const { getRankings } = useLeaderboardStore();

  useEffect(() => {
    getRankings().then((result) => {
      if (!result) return;
      setRanking(result["ranking"]);
    });
  }, [getRankings]);

  return (
    <div className="flex flex-col">
      {/* Header */}
      <dl className="w-full flex flex-row justify-between items-center pb-[0px] lg:pb-[0px]">
        <dt className="w-[100px] ml-[20px]">
          <p className={$titleStyle}>Rank</p>
        </dt>
        <dt className="flex-3 w-[140px] ml-[50px]">
          <p className={`${$titleStyle} !text-left`}>Address</p>
        </dt>
        <dt className="flex-1 w-[100px] mr-[20px]">
          <p className={`${$titleStyle} !text-right`}>FLP Earned</p>
        </dt>
      </dl>

      {/* Loading Icon */}
      {!ranking && (
        <div className="pt-[20px] mx-auto">
          <LoadingIcon />
        </div>
      )}

      {/* No Records */}
      {!ranking && (
        <div className="pt-[20px] mx-auto">
          <p>No players yet</p>
        </div>
      )}

      {/* Rows */}
      <div className="flex flex-col">
        {ranking &&
          ranking.length > 0 &&
          ranking.map((item: any, i: number) => {
            return (
              <div
                className={`${$rowStyle} group transition-all duration-300`}
                key={i}
              >
                <dl
                  className={`${$tableRow} group-hover:bg-[#eee] transition-all duration-300 shadow-none group-hover:shadow-lg group-hover:shadow-primary`}
                >
                  <dd className="w-[100px] ml-[20px]">
                    <p className={`${$labelStyle}`}>{i + 1}</p>
                  </dd>

                  <dd className="flex-3 w-[140px] ml-[50px]">
                    <p className={`${$labelStyle} !text-left`}>
                      {item["address"]}
                    </p>
                  </dd>

                  <dd className="flex-1 w-[100px] mr-[20px]">
                    <p className={`${$labelStyle} !text-right`}>
                      {item.totalFlp}
                    </p>
                  </dd>
                </dl>
              </div>
            );
          })}
      </div>
    </div>
  );
};

const $titleStyle = "text-[#fff] font-[500] text-center";
const $labelStyle = "text-[#000] font-[500] text-center";
const $rowStyle = "mt-[5px] p-[5px] rounded-[15px]";
const $tableRow =
  "w-full flex flex-row justify-between items-center text-center rounded-[15px] py-[3px] bg-white h-[44px]";
