import { Modal } from "antd";
import { capitalised } from "src/utils";
import { CloseButton } from "./close-btn";

export const ModalConfirmBet = (props: any) => {
  const {
    isVisible,
    setIsVisible,
    password,
    setPassword,
    confirmBet,
    bet,
    side,
  } = props;

  const submit = async (password: string) => {
    setPassword(password);
    confirmBet();
  };

  const handleCancel = () => {
    setIsVisible(false);
  };

  return (
    <Modal title={``} visible={isVisible} footer={null} centered={true}>
      {/* Close btn */}
      <CloseButton handleCancel={() => handleCancel()} />

      <div className="flex flex-col justify-start align-start min-h-[200px]">
        {/* Title */}
        <h5 className="font-[400] text-black text-[16px] uppercase">
          Confirm To Stake
        </h5>
        {/* Body */}
        <div className="mt-[30px]">
          <div>
            <p className="text-black">Your Password</p>
            <input
              type="password"
              name="password"
              className={$inputClass}
              placeholder=""
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </div>
        </div>
        {/* Notes */}
        <div className="pt-[20px]">
          <p className="text-black">Please confirm the following action:</p>
          <ul className="mt-[10px]">
            <li className="text-black">
              <span className={`${$labelStyle}`}>Stake</span>
              <span className="txt-dark-gradient">{bet} FLP</span>
            </li>
            <li className="text-black">
              <span className={`${$labelStyle}`}>Side</span>
              <span
                className={`${
                  side === "long" ? "text-green-500" : "text-secondary"
                }`}
              >
                {capitalised(side)}
              </span>
            </li>
          </ul>
        </div>
        {/* Confirm Button */}
        <div className="flex-1 flex flex-col justify-end pt-[20px]">
          <button
            className="mt-[20px] gradient"
            onClick={() => submit(password)}
          >
            Confirm
          </button>
        </div>
      </div>
    </Modal>
  );
};

const $labelStyle = "min-w-[60px] inline-block text-slate-600";
const $inputClass =
  "mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-primary focus:ring-primary block w-full rounded-md sm:text-sm focus:ring-1";
