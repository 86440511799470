export const SwitchButton = (props: any) => {
  const {
    tabs,
    tab,
    setTab,
    theme = "light",
    position = "center",
    size = "normal",
  } = props;

  if (!tabs) return null;

  const getName = (item: string) => {
    switch (item) {
      case "all":
        return "All";
      case "transaction":
        return "Transaction";
      case "deposit":
        return "Deposit";
      case "withdraw":
        return "Withdraw";
      default:
        return item;
    }
  };
  return (
    <div
      className={`flex flex-col sm:flex-row items-center`}
      style={{ justifyContent: position }}
    >
      {tabs.map((item: any, i: any) => {
        return (
          <button
            key={i}
            className={`${
              item === tab ? "gradient" : `${theme}-text`
            } min-w-[150px] mb-[10px] sm:mb-[0px] sm:mx-[10px] ${size}`}
            onClick={() => setTab(item)}
          >
            {getName(item)}
          </button>
        );
      })}
    </div>
  );
};
