import { useCallback, useEffect, useState } from "react";
import {
  Container,
  FeaturedGame,
  LoadingIcon,
  LoginButton,
} from "../components";
import { observer } from "mobx-react-lite";
import { useGameStore } from "src/stores";
import { GameBlock } from "src/components/game-block";
import { ModalFilterGames } from "src/components/modal-filter-games";

export const Games = observer((props: any) => {
  const { authStore } = props;
  const user = authStore.user.get();
  const { getGames } = useGameStore();

  const [games, setGames] = useState<any>(null);
  const [featuredGame, setFeaturedGame] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const getGamesCb = useCallback(
    async (setFeatured = true, query = "") => {
      const result = await getGames(query);
      if (!result) return;
      setGames(result);
      if (!setFeatured) return;
      let featuredGameList = result.filter(
        (item: any) => item.long?.length > 0 || item.short?.length > 0
      );
      if (featuredGameList.length === 0) return setFeaturedGame(result[0]);
      const idx = Math.floor(Math.random() * featuredGameList.length);
      setFeaturedGame(featuredGameList[idx]);
    },
    [getGames]
  );

  useEffect(() => {
    getGamesCb();
  }, [getGamesCb]);

  return (
    <Container>
      <div className="px-[30px] h-full inner-page">
        {!user && <LoginButton />}
        {user && (
          <div className="pt-[130px] max-w-[1200px] mx-auto">
            <FeaturedGame game={featuredGame} refresh={getGamesCb} />

            <div className="pt-[50px]">
              {games && (
                <>
                  <div className="flex flex-row items-center">
                    <h2 className="flex-1">All Games</h2>
                    <button
                      className="gradient min-w-[150px]"
                      onClick={() => setIsModalOpen(true)}
                    >
                      Filter
                    </button>
                  </div>
                  {/* Game Blocks */}
                  <div className="pt-[20px]">
                    {games.length > 0 && (
                      <div className="flex flex-row flex-wrap justify-start">
                        {games.map((item: any, i: number) => {
                          return <GameBlock key={i} game={item} />;
                        })}
                      </div>
                    )}

                    {games.length === 0 && (
                      <p className="text-center">
                        No game available at the moment
                      </p>
                    )}
                  </div>
                </>
              )}
              {!games && (
                <div className="flex justify-center items-center">
                  <LoadingIcon />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {/* Filter Game Modal */}
      <ModalFilterGames
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        refresh={getGamesCb}
      />
    </Container>
  );
});
