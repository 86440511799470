import { useState } from "react";
import * as Configs from "../config";
import { ModalSelectChain } from "./modal-select-chain";

export const SelectChain = (props: any) => {
  const chain = sessionStorage.getItem("chain");
  const selectedChain = chain ? JSON.parse(chain) : Configs.CHAINS[0];

  const [selectChainModal, setSelectChainModal] = useState(false);

  return (
    <>
      <button
        className="primary flex flex-row items-center pr-[15px] pl-[25px] py-[10px]"
        onClick={() => setSelectChainModal(true)}
      >
        {selectedChain.name}
        <img
          src={`/assets/${selectedChain.logo}`}
          alt={selectedChain.id}
          className="ml-[10px] w-[20px]"
        />
      </button>

      <ModalSelectChain
        isModalOpen={selectChainModal}
        setIsModalOpen={setSelectChainModal}
        chain={selectedChain}
      />
    </>
  );
};
