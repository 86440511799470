import { useWeb3Modal } from "@web3modal/react";

export const LoginButton = (props: any) => {
  const { open } = useWeb3Modal();

  return (
    <div className="w-full justify-center items-center flex h-screen overflow-hidden">
      <button className="gradient" onClick={() => open()}>
        Connect Wallet
      </button>
    </div>
  );
};
