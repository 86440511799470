import axios from "axios";
import * as Configs from "../config";
import { errorHandling, refreshNewToken, setHeader } from "./api";

export class TransactionStore {
  async getTransactions() {
    const url = `${Configs.API_URL}/v1/transaction/list`;
    try {
      const result: any = await axios.get(url, setHeader());
      if (!result || !result.data?.success) return false;
      refreshNewToken(result);
      return result?.data?.data;
    } catch (e) {
      return errorHandling(e);
    }
  }
}

export const STORE_TRANSACTION = "transactionStore";
