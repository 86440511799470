import { history } from "src/stores";

export const GameBlock = (props: any) => {
  const { game } = props;
  const {
    pair: gameName = "BTC",
    duration = 30,
    identifier,
    bet,
    start_price = 0,
  } = game;

  const getDuration = (duration: number) => {
    let units = "mins";
    let formtattedDuration = duration * 2;
    if (formtattedDuration >= 60) {
      units = "hour";
      if (formtattedDuration > 60) units = "hours";
      formtattedDuration = formtattedDuration / 60;
    }

    return `${formtattedDuration} ${units}`;
  };

  return (
    <div
      className="mr-[0px] md:mr-[30px] mb-[30px] py-[20px] px-[30px] min-w-[350px] bg-white rounded-[20px] min-h-[100px] transition-all duration-300 cursor-pointer shadow-none hover:shadow-lg hover:shadow-primary"
      onClick={() => history.push(`/game/${game._id}`)}
    >
      <p className="txt-dark-gradient max-w-[150px]">{identifier}</p>

      <div className="flex flex-row items-start pt-[10px]">
        <h2 className="text-black flex-1">{gameName}</h2>
        <p className="text-slate-500 text-[12px]">{getDuration(duration)}</p>
      </div>

      <div className="flex flex-row md:flex-row pt-[20px]">
        <div className="flex flex-col">
          <p className="text-black text-[12px]">Each Stake</p>
          <h2 className={`txt-dark-gradient ${$lgLabel}`}>{bet} FLP</h2>
        </div>

        <div className="flex flex-col ml-[50px]">
          <p className="text-black text-[12px]">Start Price</p>
          <h2 className={`txt-dark-gradient ${$lgLabel}`}>
            {start_price.toFixed(2)} {gameName}
          </h2>
        </div>
      </div>
    </div>
  );
};

const $lgLabel = "text-[16px] leading-[20px] mb-[0px] mt-[5px]";
