import { Rule } from "src/components/rule";
import { Container } from "../components";

export const Rules = (props: any) => {
  return (
    <Container>
      <div className="px-[30px] h-full inner-page">
        <div className="pt-[130px] max-w-[1200px] mx-auto">
          <div className="flex flex-col md:flex-row items-start">
            {/* Left Section */}
            <div className="flex-1">
              <div className="flex flex-row items-center cursor-pointer">
                <p className="txt-gradient max-w-[150px]">Rules #101</p>
              </div>
              <h1 className="txt-white max-w-[650px] text-[32px] leading-[40px] md:text-[48px] md:leading-[60px] font-[900] pt-[10px]">
                You're betting against players, not us!
              </h1>
            </div>
            {/* Right Section */}
            <Rule />
          </div>
        </div>
      </div>
    </Container>
  );
};
