import moment from "moment";
import {
  openSuccessNotification,
  openWarningNotification,
} from "./notification";
import { ModalConfirmBet } from "./modal-confirm-bet";
import { useState } from "react";
import { useGameStore } from "src/stores";
const md5 = require("md5");
export const BetBtns = (props: any) => {
  const { game, refresh } = props;
  const { bet, long = [], short = [], size = 0, prepare_end_at } = game;

  const [isVisible, setIsVisible] = useState(false);
  const [password, setPassword] = useState("");
  const [side, setSide] = useState("");

  const { betGame } = useGameStore();

  const placeBet = (side: string) => {
    // Checkings
    if (!prepare_end_at)
      return openWarningNotification("Please try again later");
    const datetime: any = moment.utc(prepare_end_at).local();
    let difference = +new Date(datetime) - +new Date();
    if (difference <= 0) {
      openWarningNotification("The game has ended staking!");
      return refresh();
    }
    setSide(side);
    // Open confirmation Modal
    setIsVisible(true);
    // refresh();
  };

  const confirmBet = async () => {
    if (!password)
      return openWarningNotification("Please enter your password to stake");

    const result = await betGame(game["_id"], {
      password: md5(password),
      side,
    });
    if (result === "bet_game_success")
      openSuccessNotification("You have staked into the game!");

    setPassword("");
    setIsVisible(false);
    refresh(false);
  };

  return (
    <div className="flex flex-row">
      <button className="bg-green-500" onClick={() => placeBet("long")}>
        Long ({long.length} / {size})
      </button>

      <button
        className="bg-secondary ml-[30px]"
        onClick={() => placeBet("short")}
      >
        Short ({short.length} / {size})
      </button>

      <ModalConfirmBet
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        password={password}
        setPassword={setPassword}
        confirmBet={confirmBet}
        bet={bet}
        side={side}
      />
    </div>
  );
};
