// ./src/stores/index.ts
import { createContext, useContext } from "react";
import { STORE_ROUTER, RouterStore, history } from "./router";
import { AuthStore, STORE_AUTH } from "./auth";
import { STORE_TRANSACTION, TransactionStore } from "./transaction";
import { GameStore, STORE_GAME } from "./game";
import { ChainStore, STORE_CHAIN } from "./chain";
import { LeaderboardStore, STORE_LEADERBOARD } from "./leaderboard";

function createStores() {
  return {
    [STORE_ROUTER]: new RouterStore(),
    [STORE_AUTH]: new AuthStore(),
    [STORE_CHAIN]: new ChainStore(),
    [STORE_GAME]: new GameStore(),
    [STORE_TRANSACTION]: new TransactionStore(),
    [STORE_LEADERBOARD]: new LeaderboardStore(),
  };
}

export const stores = createStores();

export const StoresContext = createContext(stores);

const useStores = () => useContext(StoresContext);

export function useRouterStore() {
  const { routerStore } = useStores();
  return routerStore;
}

export function useAuthStore() {
  const { authStore } = useStores();
  return authStore;
}

export function useChainStore() {
  const { chainStore } = useStores();
  return chainStore;
}

export function useGameStore() {
  const { gameStore } = useStores();
  return gameStore;
}

export function useTransactionStore() {
  const { transactionStore } = useStores();
  return transactionStore;
}

export function useLeaderboardStore() {
  const { leaderboardStore } = useStores();
  return leaderboardStore;
}

export { history };

export const generalError = { success: false, error: "general_error" };
