import { Modal } from "antd";
import { CloseButton } from "./close-btn";
import { useState } from "react";
import { SwitchButton } from "./switch-button";
export const ModalFilterGames = (props: any) => {
  const { isModalOpen, setIsModalOpen, refresh } = props;

  const [pair, setPair] = useState("all");
  const [duration, setDuration] = useState("all");
  const [stake, setStake] = useState("all");

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const filter = () => {
    let query = "";
    // Filtering
    if (pair !== "all") {
      query = `${query}&pair=${pair}`;
    }
    if (duration !== "all") {
      let formattedDuration = null;
      switch (duration) {
        case "5m":
          formattedDuration = 5;
          break;
        case "30m":
          formattedDuration = 30;
          break;
        default:
          break;
      }
      if (formattedDuration) {
        query = `${query}&duration=${formattedDuration}`;
      }
    }
    if (stake !== "all") {
      query = `${query}&stake=${stake}`;
    }
    // Formatting
    if (pair !== "all" || duration !== "all" || stake !== "all") {
      query = query.replace("&", "?");
    }

    refresh(false, query);
    setIsModalOpen(false);
  };

  return (
    <Modal
      title={``}
      visible={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      centered={true}
    >
      {/* Close btn */}
      <CloseButton handleCancel={handleCancel} />

      <div className="flex flex-col justify-start align-start min-h-[200px]">
        {/* Title */}
        <h5 className="font-[400] text-black text-[16px] uppercase">
          Filter Games
        </h5>
        {/* Filters */}
        <div className="mt-[20px]">
          <p className="text-black mb-[10px]">Pair</p>
          <SwitchButton
            tabs={["all", "BTC"]}
            tab={pair}
            setTab={setPair}
            theme="dark"
            position="flex-start"
            size="small"
          />
        </div>
        <div className="mt-[20px]">
          <p className="text-black mb-[10px]">Duration</p>
          <SwitchButton
            tabs={["all", "5m", "30m"]}
            tab={duration}
            setTab={setDuration}
            theme="dark"
            position="flex-start"
            size="small"
          />
        </div>
        <div className="mt-[20px]">
          <p className="text-black mb-[10px]">Stake Amount</p>
          <SwitchButton
            tabs={["all", 10, 50, 100]}
            tab={stake}
            setTab={setStake}
            theme="dark"
            position="flex-start"
            size="small"
          />
        </div>
        {/* Confirm Button */}
        <div className="flex-1 flex flex-col justify-end pt-[20px]">
          <button className="mt-[20px] gradient" onClick={() => filter()}>
            Apply Filter
          </button>
          {/* Disconnect Button */}
          <button
            className="mt-[20px] primary"
            onClick={() => {
              refresh();
              setIsModalOpen(false);
            }}
          >
            Reset
          </button>
        </div>
      </div>
    </Modal>
  );
};
