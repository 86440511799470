import * as React from "react";
import { Router, Switch, Route, Redirect } from "react-router";
import { history } from "./stores";

// Components
import { Auth } from "./components/auth";
import { Home } from "./pages";
import { Account } from "./pages/account";
import { AuthStore } from "./stores/auth";
import { Games } from "./pages/games";
import { Game } from "./pages/game";
import { Rules } from "./pages/rules";
import { ChainStore } from "./stores/chain";
import { Beta } from "./pages/beta";

export default function Routers() {
  const authStore = new AuthStore();
  const chainStore = new ChainStore();
  return (
    <Router history={history}>
      <Switch>
        <Route path={"/"} exact component={Home} />
        <Route
          path={"/account"}
          exact
          component={() => (
            <Auth authStore={authStore} chainStore={chainStore}>
              <Account authStore={authStore} chainStore={chainStore} />
            </Auth>
          )}
        />
        <Route
          path={"/games"}
          exact
          component={() => (
            <Auth authStore={authStore} chainStore={chainStore}>
              <Games authStore={authStore} />
            </Auth>
          )}
        />
        <Route
          path={"/game/:id"}
          exact
          component={() => (
            <Auth authStore={authStore} chainStore={chainStore}>
              <Game authStore={authStore} />
            </Auth>
          )}
        />
        <Route path={"/rules"} exact component={Rules} />
        <Route path={"/beta"} exact component={Beta} />
        <Route path="*">
          <Redirect from={"/"} to={"/"} />
        </Route>
      </Switch>
    </Router>
  );
}
