import { Modal } from "antd";
import { useState } from "react";
import { useDisconnect } from "wagmi";
import {
  openSuccessNotification,
  openWarningNotification,
} from "./notification";
import { useAuthStore } from "src/stores";
import { LoadingIcon } from "./loading-icon";
const md5 = require("md5");

export const ModalSetPwd = (props: any) => {
  const { isPwdSet, setIsPwdSet } = props;

  const { disconnect } = useDisconnect();
  const { savePassword } = useAuthStore();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const submit = async () => {
    if (!password || !confirmPassword)
      return openWarningNotification("Please enter your password");

    if (password.length < 6 || password.length > 16) {
      return openWarningNotification(
        "Password must be within 6 to 16 characters"
      );
    }

    if (password !== confirmPassword) {
      return openWarningNotification("Password is not matched");
    }

    setLoading(true);
    const result = await savePassword({
      password: md5(password),
    });
    setLoading(false);
    if (!result) return openWarningNotification("Failed to save password");
    openSuccessNotification("Your password has been set!");
    setIsPwdSet(true);
  };

  return (
    <Modal title={``} visible={!isPwdSet} footer={null} centered={true}>
      <div className="flex flex-col justify-start align-start min-h-[200px]">
        {/* Title */}
        <h5 className="font-[400] text-black text-[16px] uppercase">
          Set Password
        </h5>
        {/* Body */}
        <div className="mt-[30px]">
          <div>
            <p className="text-black">Password</p>
            <input
              type="password"
              name="password"
              className={$inputClass}
              placeholder=""
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </div>
          <div className="mt-[10px]">
            <p className="text-black">Confirm Password</p>
            <input
              type="password"
              name="confirmPassword"
              className={$inputClass}
              placeholder=""
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
            />
          </div>
        </div>
        {/* Notes */}
        <div className="pt-[20px]">
          <p className="text-black">
            Password is used to do the following actions:
          </p>
          <ul className="list-disc ml-[20px] mt-[10px]">
            <li className="text-black">Stake</li>
            <li className="text-black">Withdraw</li>
          </ul>
        </div>
        {/* Confirm Button */}
        <div className="flex-1 flex flex-col justify-end pt-[20px]">
          <button className="mt-[20px] gradient" onClick={() => submit()}>
            Confirm
          </button>
          {/* Disconnect Button */}
          <button
            className="mt-[20px] primary"
            onClick={() => {
              disconnect();
              setIsPwdSet(true);
            }}
          >
            Cancel
          </button>
        </div>
      </div>

      {loading && (
        <div className="w-full h-full bg-black/30 absolute top-0 left-0 items-center justify-center flex">
          <LoadingIcon color="#f152ff" />
        </div>
      )}
    </Modal>
  );
};

const $inputClass =
  "mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-primary focus:ring-primary block w-full rounded-md sm:text-sm focus:ring-1";
