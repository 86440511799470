import { useEffect, useState } from "react";
import { useAccount, useNetwork, useSwitchNetwork } from "wagmi";
import * as Configs from "../config";
import { useAuthStore } from "src/stores";
import { ModalSetPwd } from "./modal-set-pwd";

export const Auth = (props: any) => {
  const { children, authStore, chainStore } = props;
  const selectedChain = chainStore.chain;

  const { address } = useAccount();
  const { chain } = useNetwork();
  const { switchNetwork } = useSwitchNetwork();
  const { access } = useAuthStore();

  const [isPwdSet, setIsPwdSet] = useState(true);

  useEffect(() => {
    if (!address) {
      return authStore.logout();
    }
    if (!chain) return;

    // Check chain
    if (Configs.NETWORK === "testnet") {
      if (chain?.id !== 5) {
        switchNetwork?.(5);
      }
    }
    if (Configs.NETWORK === "mainet") {
      if (chain?.id !== selectedChain.chainId) {
        switchNetwork?.(selectedChain.chainId);
      }
    }

    // Getting Address information and setup passcode
    access({
      address: address,
    }).then((item: any) => {
      if (!item) return;
      // Get Profile
      authStore.getProfile().then((user: any) => {
        if (!user) return;
        setIsPwdSet(user?.password);
      });
    });
  }, [address, chain, switchNetwork, access, authStore, selectedChain]);

  return (
    <>
      {!isPwdSet && (
        <ModalSetPwd isPwdSet={isPwdSet} setIsPwdSet={setIsPwdSet} />
      )}
      {children}
    </>
  );
};
