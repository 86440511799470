import { Leaderboard } from "src/components/leaderboard";
import { Container, MoonAnimation } from "../components";

export const Beta = (props: any) => {
  return (
    <Container>
      <div className="px-[30px] h-full inner-page">
        <div className="pt-[130px] max-w-[1200px] mx-auto">
          <div className="flex flex-col md:flex-row items-start">
            {/* Left Section */}
            <div className="flex-1">
              <div className="flex flex-row items-center cursor-pointer">
                <p className="txt-gradient max-w-[150px]">Fortuna Labs</p>
              </div>
              <h1 className="txt-white max-w-[650px] text-[32px] leading-[40px] md:text-[48px] md:leading-[60px] font-[900] pt-[10px]">
                Beta Program Starting Now!
              </h1>

              {/* Information */}
              <div className="flex flex-col md:flex-row pt-[20px]">
                <div className="flex flex-col">
                  <p>Free $FLP</p>
                  <h2 className={`txt-gradient ${$lgLabel}`}>500 FLP</h2>
                </div>
                <div className="flex flex-col mt-[20px] md:mt-[0px] ml-[0px] md:ml-[50px]">
                  <p>From</p>
                  <h2 className={`txt-white ${$lgLabel}`}>22 Oct</h2>
                </div>
                <div className="flex flex-col mt-[20px] md:mt-[0px] ml-[0px] md:ml-[50px]">
                  <p>To</p>
                  <h2 className={`txt-white ${$lgLabel}`}>TBC</h2>
                </div>
              </div>

              {/* Notes */}
              <p className="mt-[30px] text-[10px] max-w-[600px]">
                Once the Beta Program concludes, FLP will be reset. The top 10
                winners will receive 20 FLP, which is equivalent to 20 USDT, and
                they can withdraw it after one month.
              </p>

              {/* Participate Button */}
              <button
                className="mt-[50px] gradient"
                // onClick={() => history.push("/games")}
                onClick={() => window.open("https://t.me/fortunalabs_io")}
              >
                Sign up for Beta Program
              </button>
            </div>
            {/* Right Section */}

            <div
              className="mt-[50px] md:mt-[0px] p-[50px] md:p-[100px] rounded-[30px] flex justify-center items-center bg-cover"
              style={{ backgroundImage: "url('/assets/game-asset-bg.jpg')" }}
            >
              <MoonAnimation />
            </div>
          </div>
          {/* Leaderboard */}
          <div className="pt-[50px] max-w-[800px] mx-auto">
            <h1 className="txt-white max-w-[650px] text-[32px] leading-[40px] md:text-[48px] md:leading-[60px] font-[900] pt-[10px] mx-auto text-center">
              Leaderboard
            </h1>
            <Leaderboard />
          </div>
        </div>
      </div>
    </Container>
  );
};

const $lgLabel = "text-[30px] leading-[30px] mb-[0px] mt-[5px]";
