export const Rule = (props: any) => {
  return (
    <div
      className="mt-[50px] md:mt-[0px] p-[30px] md:p-[50px] rounded-[30px] flex justify-center items-start bg-cover max-w-[600px]"
      style={{ backgroundImage: "url('/assets/game-asset-bg.jpg')" }}
    >
      <ul className="list-disc ml-[20px] mt-[10px">
        <li className="text-black">
          Predict whether the game's value will rise or fall within a specific
          time frame (e.g. BTC 30 mins).
        </li>
        <li className={$listStyle}>
          Each game has unique staking and starting times.
        </li>
        <li className={$listStyle}>Staking amounts vary for each game.</li>
        <li className={$listStyle}>
          Payouts are processed when the game reaches its end time.
        </li>
        <li className={$listStyle}>
          Bets are placed against other players, not the platform.
        </li>
        <li className={$listStyle}>
          Only $FLP tokens are accepted for staking.
        </li>
        <li className={$listStyle}>Payouts are given in $FLP tokens.</li>
        <li className={$listStyle}>
          If you are the only LONG position against 10 SHORT positions, you
          receive all 10 SHORT-staked $FLP tokens. For instance, with 10 FLP per
          stake, you'd receive 100 FLP (excluding fees).
        </li>
        <li className={$listStyle}>
          If either side lacks players, the game is canceled, and your staked
          $FLP tokens are fully refunded.
        </li>
        <li className={$listStyle}>
          A maximum 2% fee applies upon winning the game; fees decrease with
          higher tiers.
        </li>
      </ul>
    </div>
  );
};

const $listStyle = "text-black mt-[20px]";
