import { useState } from "react";
import {
  Container,
  Deposit,
  LoginButton,
  SwitchButton,
  Transactions,
  Withdraw,
  openSuccessNotification,
} from "../components";
import { observer } from "mobx-react-lite";

export const Account = observer((props: any) => {
  const { authStore, chainStore } = props;
  const user = authStore.user.get();
  const chain = chainStore.chain;
  const tabs = ["transaction", "deposit", "withdraw"];

  const [tab, setTab] = useState(tabs[0]);

  return (
    <Container>
      <div className="px-[30px] h-full inner-page">
        {!user && <LoginButton />}
        {user && (
          <div className="pt-[130px] text-center">
            <h2
              className="txt-gradient overflow-hidden text-ellipsis max-w-[200px] mx-auto cursor-pointer"
              onClick={() => {
                navigator.clipboard.writeText(user.address);
                openSuccessNotification("Address is copied!");
              }}
            >
              {user.address}
            </h2>
            <h1 className="mt-[20px] text-[24px] tracking-[5px]">
              Welcome Back
            </h1>
            {/* Balance */}
            <div className="text-left mt-[20px] rounded-[20px] bg-white max-w-[400px] mx-auto py-[20px] px-[30px] min-h-[120px]">
              <p className="text-slate-600">$FLP Balance</p>
              <p className="text-black text-[24px]">{user.flp || 0}</p>
            </div>
            {/* Switch Button */}
            <div className="mt-[50px]">
              <SwitchButton tab={tab} setTab={setTab} tabs={tabs} />
            </div>

            <div className="pt-[50px] max-w-[800px] mx-auto">
              {tab === "transaction" && <Transactions />}
              {tab === "deposit" && <Deposit chain={chain} />}
              {tab === "withdraw" && <Withdraw chain={chain} />}
            </div>
          </div>
        )}
      </div>
    </Container>
  );
});
