import { useEffect, useState } from "react";
import { history, useTransactionStore } from "src/stores";
import { LoadingIcon } from "./loading-icon";
import moment from "moment";
import { capitalised } from "src/utils";

export const Transactions = (props: any) => {
  const [transactions, setTransactions] = useState<any>(null);

  const { getTransactions } = useTransactionStore();

  useEffect(() => {
    getTransactions().then((result) => {
      setTransactions(result);
    });
  }, [getTransactions]);

  const getColor = (type: string) => {
    if (type === "stake") return "#f152ff";
    if (type === "payout") return "rgb(34, 197, 94)";
    return "#000";
  };

  return (
    <div className="flex flex-col">
      {/* Header */}
      <dl className="w-full flex flex-row justify-between items-center pb-[0px] lg:pb-[0px]">
        <dt className="w-[60px] ml-[20px]">
          <p className={$titleStype}>Type</p>
        </dt>
        <dt className="w-[140px] desktop ml-[20px]">
          <p className={`${$titleStype} !text-left`}>Game Identifier</p>
        </dt>
        <dt className="w-[100px] ml-[40px]">
          <p className={`${$titleStype} !text-left`}>$FLP</p>
        </dt>
        <dt className="w-[60px] desktop">
          <p className={$titleStype}>Status</p>
        </dt>
        <dt className="w-[150px] mr-[20px]">
          <p className={`${$titleStype} !text-right`}>Time</p>
        </dt>
      </dl>

      {/* Loading Icon */}
      {!transactions && (
        <div className="pt-[20px] mx-auto">
          <LoadingIcon />
        </div>
      )}

      {/* No Transactions */}
      {transactions && transactions.length === 0 && (
        <div className="pt-[20px] mx-auto">
          <p>No transaction yet</p>
        </div>
      )}

      {/* Rows */}
      <div className="flex flex-col">
        {transactions &&
          transactions.length > 0 &&
          transactions.map((item: any, i: number) => {
            const { _id } = item.extra?.game || {};
            return (
              <div
                className={`${$rowStyle} group transition-all duration-300 cursor-pointer`}
                key={i}
                onClick={() => _id && history.push(`/game/${_id}`)}
              >
                <dl
                  className={`${$tableRow} group-hover:bg-[#eee] transition-all duration-300 cursor-pointer shadow-none group-hover:shadow-lg group-hover:shadow-primary`}
                >
                  <dd className="w-[60px] ml-[20px]">
                    <p className={$labelStyle}>{capitalised(item.type)}</p>
                  </dd>
                  <dd className="w-[140px] ml-[20px] desktop">
                    <p className={`${$labelStyle} !text-left`}>
                      {item.extra?.game?.identifier}
                    </p>
                  </dd>
                  <dd className="w-[100px] ml-[40px]">
                    <p
                      className={`${$labelStyle} !text-left`}
                      style={{
                        color: getColor(item.type),
                      }}
                    >
                      {item.flp}
                    </p>
                  </dd>
                  <dd className="w-[60px] desktop">
                    <p className={$labelStyle}>{capitalised(item.status)}</p>
                  </dd>
                  <dd className="w-[150px] mr-[20px]">
                    <p className={`${$labelStyle} !text-right`}>
                      {item.updated_at
                        ? moment
                            .utc(item.updated_at)
                            .local()
                            .format("YYYY-MM-DD HH:mm:ss")
                        : ""}
                    </p>
                  </dd>
                </dl>
              </div>
            );
          })}
      </div>
    </div>
  );
};

const $titleStype = "text-[#fff] font-[500] text-center";
const $labelStyle = "text-[#000] font-[500] text-center";
const $rowStyle = "mt-[5px] p-[5px] rounded-[15px]";
const $tableRow =
  "w-full flex flex-row justify-between items-center text-center rounded-[15px] py-[3px] bg-white h-[44px]";
