import { useCallback, useEffect, useState } from "react";
import {
  Container,
  FeaturedGame,
  LoadingIcon,
  LoginButton,
} from "../components";
import { observer } from "mobx-react-lite";
import { useGameStore } from "src/stores";
import { useParams } from "react-router-dom";

export const Game = observer((props: any) => {
  const { authStore } = props;
  const user = authStore.user.get();
  const { getGameDetail } = useGameStore();

  const params: any = useParams();
  const { id = "" } = params;

  const [game, setGame] = useState<any>(null);

  const getGameDetailCb = useCallback(async () => {
    if (!id) return;
    const result = await getGameDetail(id);
    if (!result) return;
    setGame(result);
  }, [id, getGameDetail]);

  useEffect(() => {
    getGameDetailCb();
  }, [getGameDetailCb]);

  return (
    <Container>
      <div className="px-[30px] h-full inner-page">
        {!user && <LoginButton />}
        {user && (
          <div className="pt-[130px] max-w-[1200px] mx-auto">
            {game && (
              <>
                <FeaturedGame
                  game={game}
                  refresh={getGameDetailCb}
                  back={true}
                />
              </>
            )}

            {!game && (
              <div className="flex justify-center items-center">
                <LoadingIcon />
              </div>
            )}
          </div>
        )}
      </div>
    </Container>
  );
});
