import { Modal } from "antd";
import * as Configs from "../config";
import { CloseButton } from "./close-btn";
import { useAccount, useDisconnect } from "wagmi";
import { useState } from "react";
import { ChainStore } from "src/stores/chain";
export const ModalSelectChain = (props: any) => {
  const { isModalOpen, setIsModalOpen, chain = {} } = props;
  const chains = Configs.CHAINS;

  const { address } = useAccount();
  const { disconnect } = useDisconnect();

  const [selectedChain, setSelectedChain] = useState(chain);

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const selectChain = () => {
    const chainStore = new ChainStore();
    chainStore.updateChain(selectedChain);
    setIsModalOpen(false);
  };

  return (
    <Modal
      title={``}
      visible={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      centered={true}
    >
      {/* Close btn */}
      <CloseButton handleCancel={handleCancel} />

      <div className="flex flex-col justify-start align-start min-h-[200px]">
        {/* Title */}
        <h5 className="font-[400] text-black text-[16px] uppercase">
          Select Chain
        </h5>
        {/* Body */}
        <div className="mt-[30px]">
          {chains.map((item, i) => {
            return (
              <div
                key={i}
                className={`${$rowStyle} ${
                  item.selectable ? `cursor-pointer` : `cursor-not-allowed`
                }`}
                style={{
                  ...(selectedChain.name === item.name
                    ? {
                        background: `linear-gradient(90deg, #11fdbe 0%, #f152ff 100%)`,
                      }
                    : {
                        background: `#ccc`,
                      }),
                }}
                onClick={() => item.selectable && setSelectedChain(item)}
              >
                <div className={`${$rowInnerStyle}`}>
                  {item.logo && (
                    <img
                      src={`/assets/${item.logo}`}
                      alt={item.id}
                      className="mr-[10px] w-[20px]"
                    />
                  )}
                  <p className="text-black">{item.name}</p>
                </div>
              </div>
            );
          })}
        </div>
        {/* Confirm Button */}
        <div className="flex-1 flex flex-col justify-end">
          <button className="mt-[20px] gradient" onClick={() => selectChain()}>
            Confirm
          </button>
          {/* Disconnect Button */}
          {address && (
            <button className="mt-[20px] primary" onClick={() => disconnect()}>
              Disconnect
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
};

const $rowStyle = "mb-[20px] p-[1px] rounded-[30px] flex flex-row item-center";
const $rowInnerStyle =
  "w-full flex flex-row justify-start items-center text-center rounded-[30px] py-[10px] px-[20px] bg-white shadow-md";
