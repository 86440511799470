import { CicleAnimation, Container, SocialMenu } from "../components";
// import { history } from "src/stores";

export const Home = (props: any) => {
  return (
    <Container>
      <div className="home py-[120px] px-[30px] h-full">
        <div className="flex flex-col justify-start items-center h-full relative z-[5] text-center">
          <h1 className="txt-gradient text-[24px]">Fortuna Labs</h1>
          <h2 className="text-[30px] mt-[20px] tracking-[5px] leading-[36px]">
            Beta Program Starting
          </h2>
          <p className="mt-[50px]">
            Anticipate whether the cryptocurrency market will surge or decline
            in the upcoming moments and secure victory with a single click.
          </p>
          <div className="flex-1 flex flex-col justify-end">
            <button
              className="mt-[50px] gradient"
              // onClick={() => history.push("/games")}
              onClick={() => window.open("https://t.me/fortunalabs_io")}
            >
              Sign up for Beta Program
            </button>
          </div>
        </div>
        <CicleAnimation />

        {/* Social Menu */}
        <div className="fixed bottom-[30px] right-[30px]">
          <SocialMenu />
        </div>
      </div>
    </Container>
  );
};
