import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { configureChains, createConfig } from "wagmi";
import { arbitrum, goerli, scrollTestnet } from "wagmi/chains";
require("dotenv").config();

export const API_URL = process.env.REACT_APP_API_URL || "http://localhost:9002";
export const NETWORK = process.env.REACT_APP_NETWORK || "testnet";

export const CHAINS = [
  {
    id: "arbitrum",
    name: "Arbitrum",
    logo: "arbitrum-logo.png",
    selectable: true,
    chainId: 42161,
  },
  {
    id: "scroll",
    name: "Scroll",
    logo: "scroll-logo.png",
    selectable: true,
    chainId: 534353,
  },
  {
    id: "lukso",
    name: "LUKSO",
    logo: "lukso-logo.png",
    selectable: true,
    chainId: 42161, // Will need to change the chain ID to LUKSO
  },
  {
    id: "coming-soon",
    name: "More Coming Soon",
    logo: null,
    selectable: false,
  },
];

// Web3 Modal
export const projectId = "bbdad8334bf7190ebdac1c35573a98ed";
const chains = [arbitrum, goerli, scrollTestnet];
const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);

// Wagmi client
export const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains }),
  publicClient,
});

// Web3Modal Ethereum Client
export const ethereumClient = new EthereumClient(wagmiConfig, chains);
